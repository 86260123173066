<template>
  <!-- MAP - START -->

  <div class="template-map" :style="{ visibility: showMap }">
    <icon
      icon="#cx-app1-arrow-left"
      class="back-to-main-page"
      v-on:click="showMainDisplay"
      v-on:touchend="showMainDisplay"
    ></icon>
    <iframe
      ref="mapIframe"
      style="position: absolute; top: 0; left: 0; width: 100%; bottom: 0; border: none; overflow: auto"
      :src="mapUrl"
      frameborder="0"
      width="100%"
      height="100%"
    ></iframe>
    <div class="map-date-time" v-if="mapDate">
      <span class="map-time">{{ mapFrom }} - {{ mapTo }}</span>
      <span class="map-date">{{ mapDate }}</span>
    </div>
  </div>

  <!-- MAP - END -->

  <!-- VIDEOCONFERENCE - START -->

  <div class="template-videoconference" v-if="showVideoConference">
    <icon
      icon="#cx-app1-arrow-left"
      class="back-to-main-page"
      v-on:click="showMainDisplay"
      v-on:touchend="showMainDisplay"
    ></icon>
    <iframe
      style="position: absolute; top: 0; left: 0; width: 100%; bottom: 0; border: none; overflow: auto"
      :src="videoConferenceUrl"
      frameborder="0"
      width="100%"
      height="100%"
      allow="camera; microphone"
    ></iframe>
  </div>

  <!-- VIDEOCONFERENCE - END -->

  <!-- VIDEO - START -->
  <div
    class="template-video"
    v-if="showVideo"
    v-on:click="showMainDisplay"
    v-on:touchend="showMainDisplay"
  >
    <div
      class="back-to-main-page-div"
      v-on:click="showMainDisplay"
      v-on:touchend="showMainDisplay"
    ></div>
    <iframe
      width="100%"
      height="100%"
      :src="videoUrl"
      title=""
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowfullscreen
    ></iframe>
  </div>

  <!-- VIDEO - END -->

  <!-- ERROR SCREEN - STARAT -->

  <div class="reception-desk-wrapper" v-if="showReception">
    <!-- ERROR SCREEN - START -->

    <div class="template-error" v-if="receptionDesk.error">
      <img src="/img/logo@2x.3b392d37.png" />
      <div class="resource">{{ receptionDesk.resource }}</div>
      <div class="reception-desk-error-message">
        {{ receptionDesk.message }}
      </div>
    </div>

    <!-- ERROR SCREEN - END -->

    <!-- RECEPTION DESK - TEMPLATE 1 - START -->

    <div
      class="rd-template-1"
      v-if="!receptionDesk.error && receptionDesk.template == 1"
    >
      <div class="reception-desk">
        <p class="name">{{ receptionDesk.name }}</p>
        <div class="reception-desk-location">
          <span class="title"
            ><icon icon="#cx-app1-location"></icon>
            {{ receptionDesk.location }}</span
          >
        </div>
      </div>
      <div class="camera-div" ref="cameradiv" v-if="showCamera">
        <div ref="qrVideo" id="reader" style="width: 100%; height: 100%;"></div>
      </div>
      <div class="visit-div" v-if="checkIn">
        <div class="visit-icon">
          <icon
            style="width: 13vh;"
            :icon="visitIcon"
            :class="visitClass"
          ></icon>
        </div>
        <p v-if="checkIn && checkIn.message" class="visit-message">
          {{ checkIn.message }}
        </p>
        <hr v-if="!checkIn.error" />
        <p v-if="checkIn && checkIn.host" class="visit-host">
          {{ checkIn.host }}
        </p>
        <p v-if="checkIn && checkIn.time" class="visit-time">
          {{ checkIn.time }}
        </p>
        <p v-if="checkIn && checkIn.subject" class="visit-subject">
          {{ checkIn.subject }}
        </p>
        <p v-if="checkIn && checkIn.resource" class="visit-resource">
          {{ checkIn.resource }}
        </p>
      </div>
      <div class="visit-div visit-div-messages" v-if="!checkIn && !showCamera">
        <p v-if="!checkIn && receptionDesk" class="message-welcome">
          {{ receptionDesk.welcome }}
        </p>
        <div
          class="camera"
          v-if="!this.showCamera"
          ref="camera"
          v-on:click="showCameraDisplay"
        >
          <icon style="width: 8vh;" icon="#cx-foo2-qrcode-38x38"></icon>
        </div>
      </div>
      <div class="options">
        <div class="reception-desk-logo" id="logo" ref="logo">
          <div class="clebex-logo" v-if="!receptionDesk.logo">
            <img src="/img/logo@2x.3b392d37.png" />
          </div>
          <div class="company-logo" v-if="receptionDesk.logo">
            <img :src="receptionDesk.logo" />
          </div>
          <div class="powered-by-clebex" v-if="receptionDesk.logo">
            <powered-by-clebex />
          </div>
        </div>
        <div class="other-options" ref="otheroptions">
          <div
            class="map"
            ref="map"
            v-if="receptionDesk.map && !this.showCamera"
            v-on:click="showMapDisplay"
            v-on:touchend="showMapDisplay"
          >
            <icon style="width: 8vh;" icon="#cx-app1-5d-maps"></icon>
          </div>
          <div
            class="cancel"
            v-if="this.showCamera"
            v-on:click="
              showCamera = false;
              cancelStream();
            "
          >
            <icon style="width: 8vh;" icon="#cx-msg1-declined-100x100"></icon>
          </div>
          <div
            class="video"
            ref="video"
            v-if="receptionDesk.video_url && !this.showCamera"
            v-on:click="showVideoDisplay"
            v-on:touchend="showVideoDisplay"
          >
            <icon style="width: 10vh;" icon="#cx-men1-countries"></icon>
          </div>
          <div
            class="translations"
            ref="translations"
            v-on:click="showEnglish"
            v-on:touchend="showEnglish"
            v-if="!this.showCamera"
          >
            <icon style="width: 10vh;" icon="#cx-men1-translations"></icon>
          </div>
          <div
            class="videoconference"
            ref="videoconference"
            v-on:click="showVideoConferenceDisplay"
            v-on:touchend="showVideoConferenceDisplay"
            v-if="this.receptionDesk.videoconference_url && !this.showCamera"
          >
            <icon style="width: 10vh;" icon="#cx-app1-parent"></icon>
          </div>
        </div>
      </div>
    </div>

    <!-- RECEPTION DESK - TEMPLATE 1 - END -->
    <!-- RECEPTION DESK - TEMPLATE 2 - START -->

    <div
      class="rd-template-2"
      v-if="!receptionDesk.error && receptionDesk.template == 2"
    >
      <div class="template-2-left">
        <div class="reception-desk" ref="receptiondesk">
          <p class="name">{{ receptionDesk.name }}</p>
          <div class="reception-desk-location">
            <span class="title"
              ><icon icon="#cx-app1-location"></icon>
              {{ receptionDesk.location }}</span
            >
          </div>
        </div>
        <div class="camera-div" ref="cameradiv" v-if="showCamera">
          <div
            ref="qrVideo"
            id="reader"
            style="width: 100%; height: 100%;"
          ></div>
        </div>
        <div class="visit-div" v-if="checkIn">
          <div class="visit-icon">
            <icon
              style="width: 13vh;"
              :icon="visitIcon"
              :class="visitClass"
            ></icon>
          </div>
          <p v-if="checkIn && checkIn.message" class="visit-message">
            {{ checkIn.message }}
          </p>
          <hr v-if="!checkIn.error" />
          <p v-if="checkIn && checkIn.host" class="visit-host">
            {{ checkIn.host }}
          </p>
          <p v-if="checkIn && checkIn.time" class="visit-time">
            {{ checkIn.time }}
          </p>
          <p v-if="checkIn && checkIn.subject" class="visit-subject">
            {{ checkIn.subject }}
          </p>
          <p v-if="checkIn && checkIn.resource" class="visit-resource">
            {{ checkIn.resource }}
          </p>
        </div>

        <div
          class="visit-div visit-div-messages"
          v-if="!checkIn && !showCamera"
        >
          <p v-if="!checkIn && receptionDesk" class="message-welcome">
            {{ receptionDesk.welcome }}
          </p>
          <div
            class="camera"
            v-if="!this.showCamera"
            ref="camera"
            v-on:click="showCameraDisplay"
          >
            <icon style="width: 8vh;" icon="#cx-foo2-qrcode-38x38"></icon>
          </div>
        </div>
      </div>
      <div class="template-2-right" ref="templatetworight">
        <div class="options">
          <div class="reception-desk-logo" id="logo" ref="logo">
            <div class="clebex-logo" v-if="!receptionDesk.logo">
              <img src="/img/logo@2x.3b392d37.png" />
            </div>
            <div class="company-logo" v-if="receptionDesk.logo">
              <img :src="receptionDesk.logo" />
            </div>
            <div class="powered-by-clebex" v-if="receptionDesk.logo">
              <powered-by-clebex />
            </div>
          </div>
          <div
            class="map"
            ref="map"
            v-if="receptionDesk.map && !this.showCamera"
            v-on:click="showMapDisplay"
            v-on:touchend="showMapDisplay"
          >
            <icon style="width: 8vh;" icon="#cx-app1-5d-maps"></icon>
          </div>
          <div
            class="cancel"
            ref="cancel"
            v-if="this.showCamera"
            v-on:click="
              showCamera = false;
              cancelStream();
            "
          >
            <icon style="width: 8vh;" icon="#cx-msg1-declined-100x100"></icon>
          </div>
          <div
            class="video"
            ref="video"
            v-if="receptionDesk.video_url && !this.showCamera"
            v-on:click="showVideoDisplay"
            v-on:touchend="showVideoDisplay"
          >
            <icon style="width: 8vh;" icon="#cx-men1-countries"></icon>
          </div>
          <div
            class="translations"
            ref="translations"
            v-if="!this.showCamera"
            v-on:click="showEnglish"
            v-on:touchend="showEnglish"
          >
            <icon style="width: 8vh;" icon="#cx-men1-translations"></icon>
          </div>
          <div
            class="videoconference"
            ref="videoconference"
            v-on:click="showVideoConferenceDisplay"
            v-on:touchend="showVideoConferenceDisplay"
            v-if="this.receptionDesk.videoconference_url && !this.showCamera"
          >
            <icon style="width: 10vh;" icon="#cx-app1-parent"></icon>
          </div>
        </div>
      </div>
    </div>

    <!-- RECEPTION DESK - TEMPLATE 2 - END -->
  </div>
</template>

<script>
import httpServiceAuth, { getSubdomain } from "@/services/http-service";
import { apiEndpoints } from "@/services/constants";
import { subscribe, unsubscribeAll } from "@/services/ws-service";
import { wsEvents } from "@/services/constants";
import PoweredByClebex from "@/components/global/PoweredByClebex";
import { BrowserQRCodeReader } from "@zxing/browser";
import { errorHandler } from "@/services/error-handler";
import mapMixin from "@/services/mixins/map/map";

export default {
  name: "SelfRegistrationReceptionDesk",
  mixins: [mapMixin],
  data() {
    return {
      screensaver: null,
      screensaverTime: null,
      screensaverInterval: null,
      mapUrl: null,
      mapIframe: false,
      showMap: "hidden",
      showCamera: false,
      showReception: null,
      showVideo: false,
      showVideoConference: false,
      english: false,
      englishInterval: null,
      receptionDesk: null,
      resize: false,
      companyProfileChannel: null,
      levelChangeChannel: [],
      receptionDeskChangeChannel: null,
      socketChannel: [],
      mapDate: null,
      mapFrom: null,
      mapTo: null,
      codeReader: null,
      cameras: [],
      scanData: null,
      camerasMenu: false,
      zoom: null,
      zoomMin: 0,
      zoomMax: 0,
      zoomStep: 0,
      track: null,
      supportsCameraApi:
        "mediaDevices" in navigator && "getUserMedia" in navigator.mediaDevices,
      streaming: false,
      visitIcon: "",
      visitClass: "",
      checkIn: null
    };
  },
  components: {
    PoweredByClebex
  },
  async created() {
    await this.initialize();
    this.setSize();
    await this.subscribe();
  },
  computed: {
    code() {
      const { code } = this.$route.params;
      return code;
    }
  },
  watch: {
    $route() {
      if (this.showCamera) {
        this.cancelStream();
      }
    },
    scanData: {
      handler(newValue) {
        if (newValue != null) {
          this.fetchQrCodeData(this.scanData);
          this.cancelStream();
        }
      }
    }
  },
  methods: {
    async initialize() {
      this.receptionDesk = await this.getReceptionDeskInfo();
      this.receptionDesk = this.receptionDesk.data;
      this.setTemplate();
      this.showReception = true;

      if (!this.receptionDesk.error) {
        this.setMapUrl();
        this.setScreensaver();
        this.receptionDeskHeartbeat();
        this.setVideoconfrenceUrl();
      }

      if (!this.resize) {
        window.addEventListener("resize", () => {
          this.setSize();
        });
        this.resize = true;
      }
    },
    setTemplate() {
      if (this.receptionDesk && this.receptionDesk.template) {
        if (this.receptionDesk.template == "LANDSCAPE_WITH_HORIZONTAL_BAR") {
          this.receptionDesk.template = 1;
        } else if (
          this.receptionDesk.template == "LANDSCAPE_WITH_VERTICAL_BAR"
        ) {
          this.receptionDesk.template = 2;
        } else if (
          this.receptionDesk.template == "PORTRAIT_WITH_HORIZONTAL_BAR"
        ) {
          this.receptionDesk.template = 1;
        } else {
          this.receptionDesk.template = 1;
        }
      }
    },
    subscribe() {
      if (this.receptionDesk.error) return;
      let subscribed = subscribe(
        "company-profile",
        [getSubdomain()],
        wsEvents.companyProfile.changed,
        this.companyProfileCallback,
        true
      );
      this.socketChannel.push(subscribed);
      this.receptionDesk.parents.forEach(parent => {
        let subscribed = subscribe(
          "levels",
          [getSubdomain(), parent],
          wsEvents.levelChange.changed,
          this.levelChangeCallback,
          true
        );
        this.socketChannel.push(subscribed);
      });

      subscribed = this.receptionDeskChangeChannel = subscribe(
        "reception-desk",
        [getSubdomain(), this.receptionDesk.id],
        wsEvents.receptionDeskChange.changed,
        this.receptionDeskChangeCallback,
        true
      );
      this.socketChannel.push(subscribed);
    },
    setSize() {
      if (this.showCamera) {
        const element = this.$refs.qrVideo;
        let video = element.querySelector("video");
        // eslint-disable-next-line prettier/prettier
        video.style.height = this.$refs.cameradiv.clientHeight + "px";
        video.style.width = this.$refs.cameradiv.clientWidth + "px";
      }

      if (!this.receptionDesk.error) {
        if (this.receptionDesk.template == 2) {
          this.$refs.templatetworight.style.width =
            this.$refs.receptiondesk.clientHeight + "px";
          this.$refs.logo.style.width =
            this.$refs.receptiondesk.clientHeight + "px";
          this.$refs.logo.style.minHeight =
            this.$refs.receptiondesk.clientHeight + "px";
          this.$refs.map.style.minHeight =
            this.$refs.receptiondesk.clientHeight * 0.7 + "px";
          this.$refs.camera.style.minHeight =
            this.$refs.receptiondesk.clientHeight * 0.7 + "px";
          if (this.showCamera) {
            this.$refs.cancel.style.minHeight =
              this.$refs.receptiondesk.clientHeight * 0.7 + "px";
          }
          this.$refs.video.style.minHeight =
            this.$refs.receptiondesk.clientHeight * 0.7 + "px";
          this.$refs.translations.style.minHeight =
            this.$refs.receptiondesk.clientHeight * 0.7 + "px";
        }
      }
    },
    async getReceptionDeskInfo() {
      let receptionDeskToken = localStorage.getItem("reception_desk_token")
        ? localStorage.getItem("reception_desk_token")
        : this.generateRandomString(10);

      if (!localStorage.getItem("reception_desk_token"))
        localStorage.setItem("reception_desk_token", receptionDeskToken);

      let lang = null;
      if (this.english) {
        lang = "en";
        this.setEnglishTimeout();
      }

      return httpServiceAuth.post(
        `${apiEndpoints.company.receptionDesk}/${this.code}`,
        {
          reception_desk_token: localStorage.getItem("reception_desk_token"),
          reception_desk_lang: lang
        }
      );
    },
    async receptionDeskHeartbeat() {
      this.heartbeat();
      let self = this;
      setInterval(function() {
        self.heartbeat();
      }, 90000);
    },
    heartbeat() {
      httpServiceAuth.get(
        `${apiEndpoints.company.receptionDeskHeartbeat}/${this.receptionDesk.id}`
      );
    },
    async getMapDateTime() {
      return httpServiceAuth.get(
        `${apiEndpoints.company.displayMapDateTime}/${this.receptionDesk.map}?lang=${this.receptionDesk.language}`
      );
    },
    async setMapDateTime() {
      let mapDateTime = await this.getMapDateTime();
      this.mapDate = mapDateTime.data.date;
      this.mapFrom = mapDateTime.data.from;
      this.mapTo = mapDateTime.data.to;
    },
    async companyProfileCallback() {
      this.showMainDisplay();
      await this.initialize();
    },
    async levelChangeCallback() {
      this.showMainDisplay();
      await this.initialize();
    },
    async receptionDeskChangeCallback() {
      this.showMainDisplay();
      await this.initialize();
    },
    async showMainDisplay() {
      this.setScreensaver();
      this.showReception = true;
      this.showMap = "hidden";
      this.showVideoConference = false;
      this.showCamera = false;
      this.showVideo = false;
      this.checkIn = null;
      await this.$nextTick(function() {
        this.setSize();
      });
    },
    async showCameraDisplay() {
      this.setScreensaver();
      this.showCamera = !this.showCamera;

      if (this.showCamera) {
        this.checkIn = null;
        this.getUserMedia();
        this.getDevices();
      }
    },
    showMapDisplay() {
      this.setScreensaver();
      this.showReception = false;
      this.showMap = "visible";
      this.showVideoConference = false;
      this.showCamera = false;
      this.checkIn = null;

      this.setMapDateTime();
      if (!this.mapIframe) this.setMapUrl();
      else {
        this.$refs.mapIframe.contentWindow.postMessage(
          {
            call: "displayMapChanged",
            value: ""
          },
          "*"
        );
      }
    },
    setMapUrl() {
      const subdomain = getSubdomain();
      this.mapUrl = `${process.env.VUE_APP_5DMAPS_URL}/display/${
        this.receptionDesk.map
      }?api=${this.getMapApi()}&subdomain=${subdomain}&type=reception-desk&lang=${
        this.receptionDesk.language
      }`;

      this.mapIframe = true;
    },
    showVideoConferenceDisplay() {
      this.setScreensaver();
      this.showReception = false;
      this.showMap = "hidden";
      this.showVideoConference = true;
      this.showCamera = false;
      this.checkIn = null;
    },
    setVideoconfrenceUrl() {
      this.videoConferenceUrl = this.receptionDesk.videoconference_url;
    },
    showVideoDisplay() {
      clearInterval(this.screensaverInterval);
      this.showReception = false;
      this.showMap = "hidden";
      this.showCamera = false;
      this.showVideo = true;
      this.showVideoConference = false;
      this.setVideoUrl();
    },
    setVideoUrl() {
      this.videoUrl = this.receptionDesk.video_url.replace("watch?v=", "");
      if (this.videoUrl.includes("&")) {
        this.videoUrl = this.videoUrl.split("&")[0];
      }
      this.videoUrl =
        "https://www.youtube.com/embed/" +
        this.videoUrl +
        "?autoplay=1&amp;controls=0&amp;start=1&amp;&loop=1&amp;rel=0&amp;playlist=" +
        this.videoUrl;
    },
    setScreensaver() {
      if (
        this.receptionDesk.screen_saver_minutes &&
        this.receptionDesk.video_url
      ) {
        if (this.screensaverInterval) clearInterval(this.screensaverInterval);
        this.screensaver = this.receptionDesk.screen_saver_minutes * 60;
        let self = this;
        this.screensaverInterval = setInterval(function() {
          self.screensaver--;
          if (self.screensaver < 0) {
            self.showVideoDisplay();
          }
        }, 1000);
      } else {
        clearInterval(this.screensaverInterval);
      }
    },
    showEnglish() {
      this.english = !this.english;
      this.initialize();
    },
    setEnglishTimeout() {
      let self = this;
      this.englishInterval = setTimeout(function() {
        self.english = false;
        self.initialize();
      }, 15000);
    },
    generateRandomString(length) {
      let result = "";
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      const charactersLength = characters.length;
      for (let i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    },
    setZoomOptions(track) {
      const capabilities = track.getCapabilities();
      const settings = track.getSettings();
      if ("zoom" in settings) {
        this.zoom = settings.zoom;
        this.zoomMin = capabilities.zoom.min;
        this.zoomMax = capabilities.zoom.max;
        this.zoomStep = capabilities.zoom.step;
      }
    },
    increaseZoom() {
      if (this.zoom < this.zoomMax) {
        this.zoom += this.zoomStep;
        this.track.applyConstraints({
          advanced: [{ zoom: this.zoom }]
        });
      }
    },
    deceaseZoom() {
      if (this.zoom > this.zoomMin) {
        this.zoom -= this.zoomStep;
        this.track.applyConstraints({
          advanced: [{ zoom: this.zoom }]
        });
      }
    },
    async getUserMedia(deviceId) {
      this.camerasMenu = false;
      if (this.supportsCameraApi) {
        const self = this;
        let constraints = {
          audio: false,
          video: true,
          focusMode: "continuous"
        };
        if (deviceId) {
          constraints.deviceId = deviceId;
        } else {
          constraints = {
            ...constraints,
            video: {
              facingMode: "environment"
            }
          };
        }
        navigator.mediaDevices
          .getUserMedia(constraints)
          .then(function(stream) {
            self.track = stream.getVideoTracks()[0];
            self.setZoomOptions(self.track);
            self.handleStream(stream);
          })
          .catch(function(err) {
            self.displayGenericMessage(err);
          });
      }
    },
    async getDevices() {
      if (this.supportsCameraApi) {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const videoDevices = [];
        devices.forEach(function(device) {
          if (device.kind === "videoinput") {
            videoDevices.push({
              id: device.deviceId,
              label: device.label
            });
          }
        });
        this.cameras = videoDevices;
      }
    },
    handleStream(stream) {
      const self = this;
      const element = this.$refs.qrVideo;
      let video = element.querySelector("video");

      if (!video) {
        video = document.createElement("video");
        video.ref = "video";
        video.style.width = this.$refs.cameradiv.clientWidth + "px";
        video.style.height = this.$refs.cameradiv.clientHeight + "px";
        video.setAttribute("muted", true);
        video.playsInline = true;
        element.append(video);
        video.addEventListener("canplay", self.setStreaming, false);
      }
      video.srcObject = stream;
      video.play();
    },
    setStreaming() {
      if (!this.streaming) {
        const video = this.$refs.qrVideo.querySelector("video");
        let width = 373;
        let height = 0;
        height = video.videoHeight / (video.videoWidth / width);
        video.setAttribute("width", width);
        video.setAttribute("height", height);
        this.streaming = true;
        this.decodeOnce();
      }
    },
    resetState() {
      this.scanData = null;
      this.cameras = [];
      this.streaming = false;
      this.zoom = null;
      this.zoomMin = 0;
      this.zoomMax = 0;
      this.zoomStep = 0;
      this.track = null;
      this.$store.commit("qrCode/setQrActive", false, { root: true });
      this.$store.commit("qrCode/setQrScreenId", null, { root: true });
    },
    cancelStream() {
      const videoEl = this.$refs.qrVideo.querySelector("video");
      this.track && this.track.stop();
      if (videoEl) {
        videoEl.removeEventListener("canplay", this.setStreaming);
        videoEl.remove();
      }
      this.resetState();
      this.showCamera = null;
    },
    toggleCameraMenu() {
      if (!this.camerasMenu) {
        this.track.stop();
      }
      this.camerasMenu = !this.camerasMenu;
    },
    async decodeOnce() {
      this.codeReader = new BrowserQRCodeReader();
      const el = this.$refs.qrVideo.querySelector("video");
      this.codeReader.scan(el, (result, _, controls) => {
        // use the result and error values to choose your actions
        // you can also use controls API in this scope like the controls
        // returned from the method.
        if (result) {
          controls.stop();
          this.scanData = result.getText();
        }
      });
    },
    displayGenericMessage(error) {
      return errorHandler({
        data: {
          message: error
            ? error
            : this.displayLabelName("plan", "plan", "qr-code-error")
        }
      });
    },
    async fetchQrCodeData(url) {
      const qrCode = url.split("/").pop();
      this.checkIn = await this.visitCheckIn(qrCode);
      this.checkIn = this.checkIn.data;

      if (this.checkIn.error) {
        if (this.checkIn.error == "error") {
          this.visitClass = "visit-error";
          this.visitIcon = "#cx-msg1-declined-100x100";
        } else if (this.checkIn.error == "warning") {
          this.visitClass = "visit-warning";
          this.visitIcon = "#cx-vis1-warning-12x12";
        }
      } else {
        this.visitClass = "visit-success";
        this.visitIcon = "#cx-msg1-accepted-100x100";
      }

      let self = this;
      setTimeout(function() {
        self.checkIn = null;
        self.english = false;
        self.initialize();
      }, 5000);
    },
    async visitCheckIn(qrCode) {
      return httpServiceAuth.get(
        `${apiEndpoints.company.receptionDeskScan}/${this.receptionDesk.id}/${qrCode}`
      );
    },
    startVideoconference() {
      window.location.href = this.receptionDesk.videoconference_url;
    }
  },
  beforeUnmount() {
    this.setScreensaver();
    if (this.showCamera) {
      this.cancelStream();
    }
    unsubscribeAll(this.socketChannels);
    this.socketChannels = [];
  }
};
</script>
<style>
body {
  background: #57c2b7 0% 0% no-repeat padding-box;
}
</style>
